import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import ClaimCoupon from './components/claimcoupon';
// import ClaimCouponResponsive from './components/claimcoupon_responsive';
function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      {/* <div style={{backgroundColor:"red", position:"absolute", width:"100vw", top:0, left:0, height:"3vh", zIndex:9}}>
        {"BETA TESTING.  DO NOT USE PRODUCTION WALLETS."}
        </div> */}
      <ClaimCoupon/>
      {/* <ClaimCouponResponsive/> */}
      {/* <ClaimCouponSVG/> */}
      {/* </header> */}
    </div>
  );
}

export default App;
